.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.myBtn {
  border:1;
  border-width:1px;
  border-color: #0000;
  background-color: #0000;
  padding-left:1px;
  padding-right:1px;
  margin:0px;
  line-height:0; 
  border-style: solid;
}

.myBtn:hover{
  border-width:1px;
  border-color:green;
  border-radius:5px;
  background-color:white;
  border-style: solid;
}

.mycard{
  border-color:green;
}

.mycard:hover{
  border-width: 1.5px;
  border-color:green;
}

.nav-link{
  --bs-nav-tabs-border-width:1px;
  --bs-nav-tabs-link-active-border-color:green;
  
}

#left_panel {
  height: 100%;
  
}

#right_panel {
  height: 100%;
}


.separator {
  width: 4px; /* Width of the draggable border */
  background-color: green;
  cursor: ew-resize; /* Change cursor shape */
}

.ellipsis {

  text-overflow: ellipsis;
}
